import { StockStatusDictionary } from '@ts/stockStatus/StockStatusDictionary';

type StockStatusTextProps = {
  stockStatus: string;
  stockStatusDictionary: StockStatusDictionary;
  deliveryMin?: string;
  deliveryMax?: string;
  deliveryUnit?: string;
  stockQuantity?: number | null;
  stockLimit?: number;
};

export default function getStockStatusText({
  stockStatus,
  stockStatusDictionary,
  deliveryMin,
  deliveryMax,
  deliveryUnit,
  stockQuantity,
  stockLimit,
}: StockStatusTextProps) {
  if (!stockStatusDictionary) return undefined;

  switch (stockStatus) {
    case 'PendingSoldOut':
    // fall through
    case 'StockItem':
      if (stockLimit) {
        if (
          stockQuantity &&
          stockQuantity < stockLimit &&
          stockStatusDictionary.lessThanInStock
        ) {
          return `${stockStatusDictionary.lessThanInStock?.replace(
            '%X%',
            `${stockQuantity}`,
          )}`;
        } else if (
          // stockQuantity is supposedly passed as undefined from BE when
          // stock status is StockItem and items in stock are > 10
          ((stockQuantity && stockQuantity >= stockLimit) ||
            stockQuantity === undefined) &&
          stockStatusDictionary.moreThanStockLimitInStock
        ) {
          return `${stockStatusDictionary.moreThanStockLimitInStock?.replace(
            '%X%',
            `${stockLimit}`,
          )}`;
        }
      }
      return stockStatusDictionary.inStock;
    case 'Incoming':
      return stockStatusDictionary.inStockSoon;
    case 'DeliveryItem':
      if (
        deliveryMin &&
        deliveryMax &&
        deliveryMin !== 'undefined' &&
        deliveryMax !== 'undefined' &&
        deliveryMin !== 'NaN' &&
        deliveryMax !== 'NaN'
      ) {
        const minAndMax = [deliveryMin, deliveryMax].join('-');

        const unit =
          deliveryUnit == 'week' && stockStatusDictionary[deliveryUnit]
            ? stockStatusDictionary[deliveryUnit]
            : `${deliveryUnit}s`;
        const estimatedTime = [
          [
            [stockStatusDictionary.estimatedDeliveryTime, minAndMax].join(': '),
          ].join(' '),
          unit,
        ].join(' ');

        return estimatedTime;
      }

      return stockStatusDictionary.madeToOrder;
    case 'OutOfStock':
      return stockStatusDictionary.outOfStock;
    case 'SoldOut':
      return stockStatusDictionary.soldOut;
    default:
      return undefined;
  }
}
