import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../tailwind.config';
import { RelevantStockStatuses } from '@ts/stockStatus/RelevantStockStatuses';

const fullConfig = resolveConfig(tailwindConfig) as any;

export const getStockColor = (status: RelevantStockStatuses) => {
  switch (status) {
    case 'PendingSoldOut':
    // fall through
    case 'StockItem':
      return fullConfig.theme.colors.green.light;
    case 'DeliveryItem':
      return fullConfig.theme.colors.yellow.DEFAULT;
    case 'Incoming':
      return fullConfig.theme.colors.yellow.DEFAULT;
    case 'SoldOut':
      return fullConfig.theme.colors.red.DEFAULT;
    case 'OutOfStock':
      return fullConfig.theme.colors.red.DEFAULT;
  }
};
