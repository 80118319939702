import { cn } from '@ngg/storefront-utils';
import Icon, { IconSizes } from '../icon/icon';

import { StockStatusDictionary } from '@ts/stockStatus/StockStatusDictionary';
import { RelevantStockStatuses } from '@ts/stockStatus/RelevantStockStatuses';
import { getStockColor } from '@utils/stockStatus/getStockColor';
import getStockStatusText from '@utils/stockStatus/getStockStatusText';

type StockStatusProps = {
  className?: string;
  iconSize?: IconSizes;
  stockStatusDictionary: StockStatusDictionary;
  status: RelevantStockStatuses | null;
  stockDetails?: {
    deliveryMin?: string;
    deliveryMax?: string;
    deliveryUnit?: string;
    stockQuantity?: number | null;
    stockLimit?: number;
  };
};

const StockStatus = ({
  className,
  stockDetails,
  status,
  stockStatusDictionary,
  iconSize,
}: StockStatusProps) => {
  if (!status) return null;
  return (
    <span className={cn(['flex items-center gap-1 italic', className])}>
      <Icon name="dot" size={iconSize ?? 6} color={getStockColor(status)} />
      {getStockStatusText({
        stockStatus: status,
        stockStatusDictionary,
        ...stockDetails,
      })}
    </span>
  );
};

export default StockStatus;
